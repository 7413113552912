import React from "react"
import BtfData from "./data/btf_cb.json"
import Styles from "./css/btf_cb.module.scss"
import {isChrome,isFirefox,isIeEdge,isSafari,getBrowser} from "@tightrope/lpscripts/browserdetect"

class Btf_cb extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: BtfData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
   else if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
   else if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
   else if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
   else if (getBrowser() === "safari" && browsers.includes("safari")) {
      display = true;
    }
   else if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();
    this.state.isSafari = isSafari();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
      if (isSafari()) {
        const browserData = Object.assign(this.state.data, this.state.data.safari);
        this.setState({ data: browserData });
      }
      if (isIeEdge()) {
        const browserData = Object.assign(this.state.data, this.state.data.edge);
        this.setState({ data: browserData });
      }

      if (typeof window != "undefined") {
      window.mapParams();
    }
    });
  }

  render() {
    return (

      <div className = {Styles.btfCb} style={{ display: this.state.display }}>
              <section className={Styles.bf}>
              <h3>{this.state.data.bfHeadline}</h3>
              <h4>{this.state.data.bfSubHeadline}</h4>
              <img className = {Styles.productSS} src={this.state.data.productImg} />
              <button className = {`${Styles.install} cl1`} data-cy="cta" onClick = {() =>  { if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) return; else return window.triggerInstall();} }
                dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></button>
                {this.props.children}
                <div className={Styles.freedom}>
                <h5>{this.state.data.freedomSubHeadline}</h5>
                <div className={Styles.quadtych}>
                <div>
                <img className = {Styles.productSS} src={this.state.data.freedom1img} />
                <h6>{this.state.data.freedom1}</h6></div>
                <div>
                <img className = {Styles.productSS} src={this.state.data.freedom2img} />
                <h6>{this.state.data.freedom2}</h6></div>
                <div>
                <img className = {Styles.productSS} src={this.state.data.freedom3img} />
                <h6>{this.state.data.freedom3}</h6></div>
                <div>
                <img className = {Styles.productSS} src={this.state.data.freedom4img} />
                <h6>{this.state.data.freedom4}</h6></div>
                </div>
              </div>
              </section>
            </div>
    )
  }
}

export default Btf_cb
